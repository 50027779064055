export default class MatriculaService {
  constructor(http) {
    this._http = http
  }

  async getMatriculas(dados) {
    if (dados.filters.matriculaCpf.value) {
      const { data } = await this._http.get(
        `/api/matriculas/matriculaCpf/${dados.filters.matriculaCpf.value}`,
      )
      return data
    }

    const { data } = await this._http.get(
      `/api/matriculas/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async getMatriculasPelaConsignataria(dados) {
    const { data } = await this._http.get(
      `/api/matriculas/consignataria/matriculaCpf/${dados.filters.matriculaCpf.value}/${dados.consignatariaSelecionada}`,
    )
    return data
  }

  async getMatriculasUsuarioLogado() {
    const { data } = await this._http.get(`/api/matriculas/servidor/`)
    return data
  }

  async getMatricula(id) {
    const { data } = await this._http.get(`/api/matriculas/${id}`)
    return data
  }

  async getMatriculaServidorLogado(id) {
    const { data } = await this._http.get(
      `/api/matriculas/servidordetalhe/${id}`,
    )
    return data
  }

  async getMatriculaByConsignatariaSelecionada(matriculaId, consignatariaId) {
    const { data } = await this._http.get(
      `/api/matriculas/${matriculaId}/consignataria/${consignatariaId}`,
    )
    return data
  }
}
