import { defineStore } from 'pinia'

export const matriculaStore = defineStore('matricula', {
  state: () => {
    return {
      matriculaSelecionada: {
        id: null,
        cpf: null,
        entidadeId: null,
        entidadeNome: null,
        matricula: null,
        orgaoNome: null,
        validacao: null,
        vinculoNome: null,
      },
      matriculas: null,
    }
  },
})
